<template>
  <div>
    <!-- Dicides how to display the events depending on their type: event, online, empty, break, divider -->

    <!-- for type event, online and empty, start and end time is shown left of the event  -->
    <EventOverview v-if="event.type == 'event'" v-bind:event="event" v-bind:settings="settings" />
    <EventOverview v-if="event.type == 'online'" v-bind:event="event" v-bind:settings="settings" />
    <EmptyEventOverview v-if="event.type == 'empty'" v-bind:event="event" v-bind:settings="settings" />
    <EmptyEventOverview v-if="event.type == 'pause'" v-bind:event="event" v-bind:settings="settings" />

  </div> 
</template>

<script>
import EventOverview from './EventOverview';
import EmptyEventOverview from './EmptyEventOverview';

export default {
    name: 'Event',
    props: ["event", "settings"],
    components: {
        EventOverview,
        EmptyEventOverview,
    },
    data() {
        return {

        }
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>