<template>
<div>
<h1>Test 2</h1>

</div>
</template>

<script>

export default {
  data() {

  },

  created() {
    
  },

  methods: {
    
  },
}
</script>

<style>

</style>