<template>
<div>
  <v-sheet 
  @click="redirectToEventInfo"
  :height="height + 'px'"
  :width="width + 'px'"
  :color="boxColor"
  class="py-1"
  style="
    font-size: 9px; 
    line-height: 100%; 
    white-space: nowrap;
    overflow: hidden; 
    text-align: center;  
    border-top: 0.2px solid grey !important;
    border-bottom: 0.2px solid grey !important;"
  >
    <!-- There is space for 4 lines, each about 7 spaces -->
    {{event.start}} <br>
    {{teacher}} <br>
    <i>{{student}}</i> <br>
    {{firstTwoLetters(event.subject)}} {{event.year}} {{firstTwoLetters(event.school)}} <br>
  </v-sheet>
</div>
</template>

<script>
import {getPersonsFirstName, getPersonsLastName} from '@/assets/js/dataUtils.js';
import {width as standartWidth, calculateHeight} from '@/assets/js/overviewSettings';
import {calculateTimeBetween} from '@/assets/js/timeUtils.js';

export default {
  name: 'EventOverview',
  props: ["event", "settings"],

  data() {
    return {
      teacher: '',
      student: '',
      height: 0,
      width: standartWidth,
      boxColor: "accent",
    }
  },

  watch: {
    event: async function() {
      this.asignHeight();
      this.teacher = await getPersonsFirstName(this.event.teacherRef) + ' ' + this.firstTwoLetters(await getPersonsLastName(this.event.teacherRef));
      this.student = await getPersonsFirstName(this.event.studentRef) + ' ' + this.firstTwoLetters(await getPersonsLastName(this.event.studentRef));
    }
  },

  async created() {
    this.asignHeight();
    this.teacher = await getPersonsFirstName(this.event.teacherRef) + ' ' + this.firstTwoLetters(await getPersonsLastName(this.event.teacherRef));
    this.student = await getPersonsFirstName(this.event.studentRef) + ' ' + this.firstTwoLetters(await getPersonsLastName(this.event.studentRef));
    this.colorSingleEventsDarker();
  },

  methods: {
    firstTwoLetters(str) {
      return str.substring(0,2);
    },

    asignHeight(){
      let duration = calculateTimeBetween(this.event.start, this.event.end);
      this.height = calculateHeight(duration, this.settings.minEventDuration);
    },

    colorSingleEventsDarker(){
      if(this.event.seriesReference == ''){
        this.boxColor = "accent darken-3"
      }
    },

    redirectToEventInfo() {
      /* redirect to EventInfo view and pass with it the id of the event */
      this.$router.push('/event/' + this.event.id);
    },
  }
}
</script>

<style>

</style>