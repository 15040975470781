<template>
<div>
  <h1>Test</h1>
</div>
</template>

<script>

export default {
  name: 'Test',

  data() {
    return {
    }
  },

  async created() {
    
  },

  methods: {
  }
}
</script>

<style>
</style>
