<template>
<div>
  <div v-if="events.length" >
      <v-divider class="my-5"/>
      <span @click="$router.push('/raumplan/' + date + '/' + room.id)" style="font-size: 10px;">Online</span>
    </div>

  <!-- online Events -->
  <v-container>
    <v-row row wrap>
      <div 
      v-for="event in events" 
      v-bind:key="event.id"
      class="mr-1 mb-1">
        <EventDeciderOverview v-bind:event="event" v-bind:settings="settings" />
      </div> 
    </v-row>
  </v-container>

</div>
</template>

<script>
import EventDeciderOverview from './EventDeciderOverview';
import {auth, eventsCollection} from '@/firebase';
import {width as standartWidth} from '@/assets/js/overviewSettings';

export default {
  name: 'OverviewRoom',
  props: ['room', 'date', 'settings', 'triggerReloadEvents'],

  components: {
    EventDeciderOverview,
  },

  data() {
    return {
      events: [],
      width: standartWidth,
      instituteId: '',
      firestoreListener: null, //used to unsubcribe to the snapshot listener bevor destroying the component
    }
  },

  async created () {
    //Get the institueId of the currently logged in user
    this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
    this.getEvents();
  },

  beforeDestroy() {
    if (this.firestoreListener) {
      this.firestoreListener(); //unsubscribe from the snapshot listener
    }
  },

  watch: {
    triggerReloadEvents: function() {
      this.getEvents();
    }
  },

  methods: {
    /**
     * @description loads the events on this.date at this.room.id from fiebase
     */
    getEvents() {
      if(this.room.name == 'Online') {
        this.firestoreListener = eventsCollection
        .where('instituteId', '==', this.instituteId)
        .where('date', '==', this.date)
        .where('roomId', '==', this.room.id)
        .orderBy('teacherRef')
        .orderBy('start')
        .onSnapshot(snapshot => {
          this.events = []
          snapshot.forEach(doc => {
            let event = doc.data()
            event.id = doc.id
            this.events.push(event)
          })
        })
      } else {
        console.log('non-online events should be retrieved, which is not wanted in Componnent OverviewOnlineRoom')
      }
    },
  }
}
</script>

<style>

</style>