<template>
<div>
  <v-sheet 
  @click="redirectToPrefilledAddEventForm"
  :height="height + 'px'"
  :width="width  + 'px'"
  color="var(--v-primary-lighten5)"
  outlined
  class="text-caption pa-1"
  style="line-height: 90%;
  border-top: 0.2px solid grey !important;
  border-bottom: 0.2px solid grey !important;"
  >
  </v-sheet>
</div>
</template>

<script>
import {mapState} from 'vuex';
import {width as standartWidth, calculateHeight} from '@/assets/js/overviewSettings';
import {calculateTimeBetween} from '@/assets/js/timeUtils.js';

export default {
  name: 'EmptyEventOverview',
  props: ["event", "settings"],

  data() {
    return {
      height: 0,
      width: standartWidth,
    }
  },

  watch: {
    event: function() {
      this.asignHeight();
    }
  },

  created() {
    this.asignHeight();
  },

  computed: {
    ...mapState(['userProfile']),
  },

  methods: {
    asignHeight(){
      if(this.event.type == 'pause') {
        this.height = calculateHeight(this.event.duration, this.settings.minEventDuration);
      } else if (this.event.type == 'empty'){
        let duration = calculateTimeBetween(this.event.start, this.event.end);
        this.height = calculateHeight(duration, this.settings.minEventDuration);
      }
    },

    redirectToPrefilledAddEventForm(){

      /* Check if the type of the event is a pause or an empty event.
      If it is an empty event, redirect to AddEvent */
      if(this.event.type != 'pause'){
        /* add date, time and room to store */
        this.$store.commit('setprefillAddEvent', {key: 'date', value: this.event.date});
        this.$store.commit('setprefillAddEvent', {key: 'start', value: this.event.start});
        this.$store.commit('setprefillAddEvent', {key: 'end', value: this.event.end});
        this.$store.commit('setprefillAddEvent', {key: 'room', value: {'name': this.event.room, 'id': this.event.roomId}});

        /* redirect to addEvent view */
        this.$router.push('/addevent');
      }
    },
    
  }
}
</script>

<style>

</style>