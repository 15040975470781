/* heigth and width in pixel for an event with length minEventDuration (most of the times 45min) */
const height = 45;
const width = 40;
const distanceToEventsOutsideOpenings = 20; //minimal distance between Events in and outside the opening hours

function calculateHeight(duration, minEventDuration) {
    return height * duration / minEventDuration;
}

export {
  height,
  width,
  distanceToEventsOutsideOpenings,
  calculateHeight,

}