<template>
<div>
  <h4 @click="redirectToRaumplan('')" style="white-space: nowrap;">{{ date | formatDateWithShortWeekday }}</h4>

  <table>
    <tr>
      <td @click="redirectToRaumplan(room.id)" style="vertical-align:top; text-align:center;" v-for="room in normalRooms" v-bind:key="room.id">
        {{room.shortName}}
      </td>
    </tr>
    <tr>
      <td style="vertical-align:top;" v-for="room in normalRooms" v-bind:key="room.id">
        <OverviewRoom 
          v-bind:room="room" 
          v-bind:date="date" 
          v-bind:settings="settings"  
          v-bind:triggerReloadEvents="triggerReloadEvents"
          v-bind:distanceToTop="distanceToTop" 
          v-bind:distanceToBottom="distanceToBottom" 
        />
      </td>
    </tr>    
  </table>

    <!-- Online Events, since they are not fixed to a specific room -->
    <div v-for="room in onlineRooms" v-bind:key="room.id">
      <OverviewOnlineRoom
        v-bind:room="room" 
        v-bind:date="date" 
        v-bind:settings="settings"  
        v-bind:triggerReloadEvents="triggerReloadEvents"
      />
    </div>

</div>
</template>

<script>
import OverviewRoom from './OverviewRoom';
import OverviewOnlineRoom from './OverviewOnlineRoom';

import {getInsituteSettings} from '@/assets/js/dataUtils';

export default {
  name: 'OverviewDay',
  props: ['date', 'triggerReloadEvents', 'distanceToTop', 'distanceToBottom'],

  components: {
    OverviewRoom,
    OverviewOnlineRoom,
  },

  data(){
    return {
      settings: {},
      normalRooms: [],
      onlineRooms: [],
    };
  },

  async created () {
    this.settings = await getInsituteSettings();
    this.splitNormalAndOnlineRooms();
  },

  methods: {
    /**
     * @description splitts the array of rooms (this.settings.rooms) up, in an array of normal and online rooms (this.normalRooms, this.onlineRooms)
     */
    splitNormalAndOnlineRooms() {
      for(let i=0; i<this.settings.rooms.length; i++){
        let room = this.settings.rooms[i];
        if(room.name != 'Online'){
          this.normalRooms.push(room);
        } else {
          this.onlineRooms.push(room);
        }
      }
    },

    redirectToRaumplan(roomId) {
      this.$router.push('/raumplan/' + this.date + '/' + roomId)
    }

  }
}
</script>

<style>

</style>