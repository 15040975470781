<template>
<div>

  <v-card flat>
    <v-card-title >
      Wochenansicht
      <v-spacer></v-spacer>

      <v-btn 
      v-if="!showsThisWeek()"
      color="secondary"
      depressed 
      x-small
      @click="redirectToToday"
      class="mx-3"
      >
      Diese Woche
      </v-btn>
    </v-card-title>
  </v-card>

  <!-- Date Toolbar -->
  <v-toolbar flat class="mt-n3">
      <v-icon @click="previousWeek">
        mdi-chevron-left
      </v-icon>
    <v-spacer></v-spacer>
    <v-toolbar-title @click="toggleDatePicker">
      {{ this.beginingOfWeek | formatDate }} - {{ this.endOfWeek | formatDate }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
      <v-icon @click="nextWeek">
        mdi-chevron-right
      </v-icon>
  </v-toolbar>

  <SelectDate v-if="displayDatePicker" v-bind:date="date" v-on:changeDate="changeDate" />

  
  <OverviewWeek v-bind:date="date" v-bind:triggerReloadEvents="triggerReloadEvents" />
  <v-divider class="my-5" />

  <v-card flat>
    <v-card-text>
      Darauf folgende Woche
    </v-card-text>
  </v-card>

  <OverviewWeek v-bind:date="datePlusOneWeek" v-bind:triggerReloadEvents="triggerReloadEvents" />
</div>
</template>



<script>
import OverviewWeek from '@/components/Overview/OverviewWeek';
import SelectDate from '@/components/SelectDate.vue'
import {calculateDateAtWeekday, addDays} from '@/assets/js/timeUtils.js';

export default {
  name: 'Overview',

  props: {
    routeDate: { //date got from route
        type: String,
        default: new Date().toISOString().substr(0,10) //today in format 'yyyy-mm-dd'
    },
  },

  components: {
    OverviewWeek,
    SelectDate,
  },

  data() {
    return {
      date: this.routeDate, //a date in the week we want the overview from
      displayDatePicker: false,
      triggerReloadEvents: 0, //is pased as a prop to the children. Is watched in OverviewRoom.vue and there, the events are reloaded
      datePlusOneWeek: '',
      beginingOfWeek: '',
      endOfWeek: '',
    }
  },

  created() {
    this.datePlusOneWeek = addDays(this.date, 7);
    this.beginingOfWeek = calculateDateAtWeekday(this.date, 1);
    this.endOfWeek = addDays(this.beginingOfWeek, 4);
  },

  watch: {
    //we need this watcher to also load new events when clicking on "back" in the browser
    routeDate: function() {
      this.date = this.routeDate;
      this.getEvents();
    },

    date: function() {
      this.datePlusOneWeek = addDays(this.date, 7);
      this.beginingOfWeek = calculateDateAtWeekday(this.date, 1);
      this.endOfWeek = addDays(this.beginingOfWeek, 4);
    }
  },

  methods: {
    nextWeek() {
      this.date = addDays(this.date, 7);
      this.updateEvents();
    },

    previousWeek() {
      this.date = addDays(this.date, -7);
      this.updateEvents();
    },

    /**
     * @description Changes the date of the displayed events to "value"
     * ! Is built to change the date via the component DatePicker
     */
    changeDate(value){
      this.date = value;
      this.displayDatePicker = false;
      this.updateEvents();
    },

    toggleDatePicker(){
      this.displayDatePicker = !this.displayDatePicker;
    },
    
    getEvents() {
      this.triggerReloadEvents += 1 //change to trigger load the events in OverviewRoom again.
    },

    /**
     * @description redirects to the overview with this.date
     */
    updateEvents() {
      this.$router.push({
        name: 'Overview', 
        params: {
          routeDate: this.date, 
        }
      })
      //then, the events will the updated automatically through the watchers of routeDate and routeRoom
    },

    showsThisWeek() {
      let beginingOfThisWeek = calculateDateAtWeekday(new Date().toISOString().substr(0,10), 1);
      return this.beginingOfWeek == beginingOfThisWeek;
    },

    redirectToToday() {
      this.date = new Date().toISOString().substr(0,10);
      this.updateEvents();
    },
  }
}
</script>

<style>

</style>