<template>
<div>

  <!-- eventsBeforeOpening -->
  <div 
    v-for="event in eventsBeforeOpening" 
    v-bind:key="event.id"
    >
      <EventDeciderOverview v-bind:event="event" v-bind:settings="settings" />
  </div>

  <!-- empty sheet for distance between eventsBeforeOpening (or the top if none are existing) and EventsDuringOpeningHours -->
  <v-sheet 
  :height="distanceToTop - heightOfEventsBeforeOpening + 'px'"
  :width="width  + 'px'"
  />

  <!-- EventsDuringOpeningHours -->
  <div 
    v-for="event in eventsDuringOpeningHours" 
    v-bind:key="event.id">
      <EventDeciderOverview v-bind:event="event" v-bind:settings="settings" />
  </div>


  <!-- empty sheet for distance between EventsDuringOpeningHours and eventsAfterClosing -->
  <v-sheet 
  :height="distanceToBottom - heightOfEventsAfterClosing + 'px'"
  :width="width  + 'px'"
  />

  <!-- eventsAfterClosing -->
  <div
    v-for="event in eventsAfterClosing" 
    v-bind:key="event.id"
    >
      <EventDeciderOverview v-bind:event="event" v-bind:settings="settings" />
  </div>  

</div>
</template>

<script>
import EventDeciderOverview from './EventDeciderOverview';
import {auth, eventsCollection} from '@/firebase';
import {fillEvents} from '@/assets/js/fillEvents';
import {width as standartWidth, distanceToEventsOutsideOpenings, calculateHeight} from '@/assets/js/overviewSettings';
import {calculateTimeBetween} from '@/assets/js/timeUtils.js';

export default {
  name: 'OverviewRoom',
  props: ['room', 'date', 'settings', 'triggerReloadEvents', 'distanceToTop', 'distanceToBottom'],

  components: {
    EventDeciderOverview,
  },

  data() {
    return {
      events: [],
      eventsBeforeOpening: [],
      eventsDuringOpeningHours: [],
      eventsAfterClosing: [],
      onlineEvents: [],
      width: standartWidth,
      heightOfEventsBeforeOpening: 0,
      heightOfEventsAfterClosing: 0,
      instituteId: '',
      firestoreListener: null, //used to unsubcribe to the snapshot listener bevor destroying the component
    }
  },

  async created () {
    //Get the institueId of the currently logged in user
    this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
    this.getEvents();
  },

  computed: {
    
  },

  beforeDestroy() {
    if (this.firestoreListener) {
      this.firestoreListener(); //unsubscribe from the snapshot listener
    }
  },

  watch: {
    /* If events is changed, the filled in Events will be newly calculated. This could happen eg when an other user changes an event */
    events(){
      this.eventsBeforeOpening = [];
      this.eventsDuringOpeningHours = [];
      this.eventsAfterClosing = [];
      this.onlineEvents = [];

      [this.eventsBeforeOpening, this.eventsDuringOpeningHours, this.eventsAfterClosing, this.onlineEvents] 
        = fillEvents(this.events, this.settings, this.date, this.room);

      //calculate Height of Events before Opening
      this.heightOfEventsBeforeOpening = 0;
      for(let i= 0; i < this.eventsBeforeOpening.length; i++){
        let event = this.eventsBeforeOpening[i];
        let duration = calculateTimeBetween(event.start, event.end);
        this.heightOfEventsBeforeOpening += calculateHeight(duration, this.settings.minEventDuration);
      }
      //in OverviewWeek, calculate the maximum height of all the rooms on all the dates in the week
      this.$parent.$emit('updateDistanceToTop', this.heightOfEventsBeforeOpening);

      //calculate Height of Events after Closing
      this.heightOfEventsAfterClosing = 0;
      for(let i= 0; i < this.eventsAfterClosing.length; i++){
        let event = this.eventsAfterClosing[i];
        let duration = calculateTimeBetween(event.start, event.end);
        this.heightOfEventsAfterClosing += calculateHeight(duration, this.settings.minEventDuration);
      }
      //in OverviewWeek, calculate the maximum height of all the rooms on all the dates in the week
      this.$parent.$emit('updateDistanceToBottom', this.heightOfEventsAfterClosing);
    },

    triggerReloadEvents: function() {
      this.getEvents();
    }
  },

  methods: {
    /**
     * @description loads the events on this.date at this.room.id from fiebase
     */
    getEvents() {
      if(this.room.name != 'Online') {
        this.firestoreListener = eventsCollection
        .where('instituteId', '==', this.instituteId)
        .where('date', '==', this.date)
        .where('roomId', '==', this.room.id)
        .orderBy('start')
        .onSnapshot(snapshot => {
          this.events = []
          snapshot.forEach(doc => {
            let event = doc.data()
            event.id = doc.id
            this.events.push(event)
          })
        })
      } else {
        this.firestoreListener = eventsCollection
        .where('instituteId', '==', this.instituteId)
        .where('date', '==', this.date)
        .where('roomId', '==', this.room.id)
        .orderBy('teacherRef')
        .orderBy('start')
        .onSnapshot(snapshot => {
          this.events = []
          snapshot.forEach(doc => {
            let event = doc.data()
            event.id = doc.id
            this.events.push(event)
          })
        })
      }
    },
  }
}
</script>

<style>

</style>