<template>
<div>
  
  <v-container>
    <v-row dense>
        <v-col class="mb-5">
          <OverviewDay 
          @updateDistanceToTop=updateDistanceToTop 
          @updateDistanceToBottom=updateDistanceToBottom
          v-bind:date="dateMonday" 
          v-bind:triggerReloadEvents="triggerReloadEvents" 
          v-bind:distanceToTop="distanceToTop"
          v-bind:distanceToBottom="distanceToBottom" 
          />
        </v-col>
        <v-col class="mb-5">
          <OverviewDay 
          @updateDistanceToTop=updateDistanceToTop
          @updateDistanceToBottom=updateDistanceToBottom
          v-bind:date="dateTuesday" 
          v-bind:triggerReloadEvents="triggerReloadEvents"
          v-bind:distanceToTop="distanceToTop"
          v-bind:distanceToBottom="distanceToBottom" 
          />
        </v-col>
        <v-col class="mb-5">
          <OverviewDay 
          @updateDistanceToTop=updateDistanceToTop
          @updateDistanceToBottom=updateDistanceToBottom
          v-bind:date="dateWednesday" 
          v-bind:triggerReloadEvents="triggerReloadEvents"
          v-bind:distanceToTop="distanceToTop"
          v-bind:distanceToBottom="distanceToBottom" 
          />
        </v-col>
        <v-col class="mb-5">
          <OverviewDay 
          @updateDistanceToTop=updateDistanceToTop
          @updateDistanceToBottom=updateDistanceToBottom
          v-bind:date="dateThursday" 
          v-bind:triggerReloadEvents="triggerReloadEvents"
          v-bind:distanceToTop="distanceToTop"
          v-bind:distanceToBottom="distanceToBottom" 
          />
        </v-col>
        <v-col class="mb-5">
          <OverviewDay 
          @updateDistanceToTop=updateDistanceToTop
          @updateDistanceToBottom=updateDistanceToBottom
          v-bind:date="dateFriday" 
          v-bind:triggerReloadEvents="triggerReloadEvents"
          v-bind:distanceToTop="distanceToTop"
          v-bind:distanceToBottom="distanceToBottom" 
          />
        </v-col> 
    </v-row>
  </v-container>
</div>
</template>

<script>
import OverviewDay from './OverviewDay';
import {calculateDateAtWeekday, addDays} from '@/assets/js/timeUtils';
import {distanceToEventsOutsideOpenings} from '@/assets/js/overviewSettings';

export default {
  name: 'OverviewWeek',
  props: ['date', 'triggerReloadEvents'],

  components: {
    OverviewDay,
  },

  watch: {
    date() {
      this.beginingOfWeek = calculateDateAtWeekday(this.date, 1);
      this.dateMonday = this.beginingOfWeek;
      this.dateTuesday = addDays(this.beginingOfWeek, 1);
      this.dateWednesday = addDays(this.beginingOfWeek, 2);
      this.dateThursday = addDays(this.beginingOfWeek, 3);
      this.dateFriday = addDays(this.beginingOfWeek, 4);
    },

    triggerReloadEvents: function() {
      //reset distanceToTop to 0
      this.distanceToTop = 0;
      this.distanceToBottom = 0;
    }
  },

  data() {
    return {
      beginingOfWeek: '',
      dateMonday: '',
      dateTuesday: '',
      dateWednesday: '',
      dateThursday: '',
      dateFriday: '',
      distanceToTop: 0, //distance to the Top in the Overview view = max(heightOfEventsBeforeOpening of all rooms on all dates + distanceToEventsOutsideOpenings) Also see OverviewRoom.vue
      distanceToBottom: 0,
    };
  },

  created() {
    this.beginingOfWeek = calculateDateAtWeekday(this.date, 1);
    this.dateMonday = this.beginingOfWeek;
    this.dateTuesday = addDays(this.beginingOfWeek, 1);
    this.dateWednesday = addDays(this.beginingOfWeek, 2);
    this.dateThursday = addDays(this.beginingOfWeek, 3);
    this.dateFriday = addDays(this.beginingOfWeek, 4);
  },

  methods: {

    /**
     * @description If there are events before opening, those are added at the top. 
     * Then, the events in all of the other rooms and days have to move downwards, 
     * to align the same times corretly next to each other
     */
    updateDistanceToTop(heightOfEventsBeforeOpening){
      /* add height for space between the events before and in opening hours, if there are any before opening */
      if(heightOfEventsBeforeOpening > 0){
        heightOfEventsBeforeOpening += distanceToEventsOutsideOpenings;
      }

      /* if the height of the events before opening time are higher than what we have seen before, update it */
      if(heightOfEventsBeforeOpening > this.distanceToTop){
        this.distanceToTop = heightOfEventsBeforeOpening;
      }
    },

    /**
     * @description If there are events after closing, those are added at the bottom. 
     * Then, the online events in all of the other rooms and days have to move downwards, 
     * to align them corretly next to each other
     */
    updateDistanceToBottom(heightOfEventsAfterClosing){
      /* add height for space between the events before and in opening hours, if there are any before opening */
      if(heightOfEventsAfterClosing > 0){
        heightOfEventsAfterClosing += distanceToEventsOutsideOpenings;
      }

      /* if the height of the events before opening time are higher than what we have seen before, update it */
      if(heightOfEventsAfterClosing > this.distanceToBottom){
        this.distanceToBottom = heightOfEventsAfterClosing;
      }
    },
  }
}
</script>

<style>

</style>